<template>
  <div class="button-controller">
    <SharedKitButton
      :class="['button-controller__trigger', triggerClass]"
      :color-preset="ColorPresets.DOTA.PRIMARY"
      :size-preset="sizePreset"
      :type-preset="TypePresets.ROUND"
      :text="$t('generalAuth.triggerButton')"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import type { IButtonControllerProps } from './ButtonController.types';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { AuthEvents } from '~/repository/amplitude/events/auth';

const props = defineProps<IButtonControllerProps>();
const { amplitudeSourceOfClick, isInBurger, isInHeader } = toRefs(props);

const authPopupStore = useAuthPopupStore();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const sizePreset = computed(() => {
  if (!isInHeader.value) return SizePresets.M;
  return changeXL(SizePresets.M, SizePresets.XS);
});

const triggerClass = computed(() => ({
  'button-controller__trigger--in-burger': isInBurger.value,
  'button-controller__trigger--in-header': isInHeader.value,
}));

const handleClick = () => {
  AuthEvents.entryClicked({ 'Button Place': amplitudeSourceOfClick.value, 'Url': location.href });
  authPopupStore.open();
};
</script>

<style scoped lang="scss" src="./ButtonController.scss" />
